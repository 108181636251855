@use "../utilities/variables" as var;
@use "../utilities/mixins" as mix;

.toggle-password-button {
    background: none;
    border: none;
    padding: 4px;
    margin: 0;

    svg {
        vertical-align: middle;
        width: 17px;
        height: 17px;
    }

    &__icon-off {
        display: none;
    }

    &__icon-on {
        display: block;
    }

    &[aria-checked=true] {

        .toggle-password-button__icon-off {
            display: block;
        }

        .toggle-password-button__icon-on {
            display: none;
        }
    }
}
