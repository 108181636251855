@charset "UTF-8";
/*
* z-index de la aplicación
*/
.section-container {
  padding: 0 15px;
}
.section-container__wrapper {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .section-container {
    padding: 0 48px;
  }
}
@media (min-width: 992px) {
  .section-container {
    padding: 0 15px;
  }
  .section-container__wrapper {
    max-width: 1200px;
  }
}
.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.icon.icon-email {
  background-image: url(/static/website/images/icons/icon-email.svg);
}

.icon.icon-whatsapp {
  background-image: url(/static/website/images/icons/icon-whatsapp.svg);
}

.icon.icon-twitter {
  background-image: url(/static/website/images/icons/icon-twitter.svg);
}

.icon.icon-logo-google {
  background-image: url(/static/website/images/icons/icon-logo-google.svg);
}

.icon.icon-close-button {
  background-image: url(/static/website/images/icons/icon-close-button.svg);
}

/*
* z-index de la aplicación
*/
.toggle-password-button {
  background: none;
  border: none;
  padding: 4px;
  margin: 0;
}
.toggle-password-button svg {
  vertical-align: middle;
  width: 17px;
  height: 17px;
}
.toggle-password-button__icon-off {
  display: none;
}
.toggle-password-button__icon-on {
  display: block;
}
.toggle-password-button[aria-checked=true] .toggle-password-button__icon-off {
  display: block;
}
.toggle-password-button[aria-checked=true] .toggle-password-button__icon-on {
  display: none;
}

/*
* z-index de la aplicación
*/
.form-input {
  position: relative;
}
.form-input__label {
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 18px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  padding: 0 6px;
  color: #6F6F6F;
  background: rgba(255, 255, 255, 0);
  transition: all 200ms ease-in-out;
}
.form-input__input {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  padding: 16px 22px;
  width: 100%;
  color: #000000;
  background: #F6F6F6;
  border: 2px solid #F6F6F6;
  border-radius: 16px;
  outline: none;
  transition: all 200ms ease-in-out;
}
.form-input__input:-webkit-autofill, .form-input__input:-webkit-autofill:hover, .form-input__input:-webkit-autofill:focus, .form-input__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.form-input__input:focus, .form-input__input:valid {
  background: #FFFFFF;
  border-color: #E5E5E5;
  border-radius: 16px;
}
.form-input__input:focus {
  border-color: rgba(22, 112, 228, 0.8);
}
.form-input__input:focus + .form-input__label {
  top: -6px;
  left: 14px;
  background: #FFFFFF;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-input:not(.form-control-forced-focus) .form-input__input:valid + .form-input__label {
  top: -6px;
  left: 14px;
  background: #FFFFFF;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-input.form-control-invalid .form-input__label {
  color: #DE1947;
}
.form-input.form-control-invalid .form-input__input {
  border-color: #DE1947 !important;
}

.form-input.form-control-filled .form-input__input {
  background: #FFFFFF;
  border-color: #E5E5E5;
  border-radius: 16px;
}
.form-input.form-control-filled .form-input__input:focus {
  border-color: rgba(22, 112, 228, 0.8);
}
.form-input.form-control-filled .form-input__label {
  top: -6px;
  left: 14px;
  background: #FFFFFF;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-input.form-input--optional .form-input__optional {
  pointer-events: none;
  position: absolute;
  top: 17px;
  right: 24px;
  color: #6F6F6F;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}
.form-input.form-input--optional .form-input__input {
  padding: 16px 88px 16px 22px;
}

/*
* z-index de la aplicación
*/
.form-input-button {
  position: relative;
  display: flex;
  align-items: center;
  background: #F6F6F6;
  border: 2px solid #F6F6F6;
  border-radius: 16px;
  padding-right: 18px;
  transition: all 200ms ease-in-out;
}
.form-input-button:focus-within {
  background: #FFFFFF;
  border-color: rgba(22, 112, 228, 0.8);
}
.form-input-button__label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 15px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  padding: 0 6px;
  color: #6F6F6F;
  background: rgba(255, 255, 255, 0);
  transition: all 200ms ease-in-out;
}
.form-input-button__input {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  padding: 16px 0 16px 22px;
  width: 100%;
  color: #000000;
  background: #F6F6F6;
  border: none;
  border-radius: 16px;
  outline: none;
  transition: all 200ms ease-in-out;
}
.form-input-button__input:-webkit-autofill, .form-input-button__input:-webkit-autofill:hover, .form-input-button__input:-webkit-autofill:focus, .form-input-button__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.form-input-button__input:focus, .form-input-button__input:valid {
  background: #FFFFFF;
  border-color: #E5E5E5;
  border-radius: 16px;
}
.form-input-button__input:focus {
  border-color: rgba(22, 112, 228, 0.8);
}
.form-input-button__input:focus + .form-input-button__label, .form-input-button__input:valid + .form-input-button__label {
  top: -8px;
  left: 12px;
  background: #FFFFFF;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-input-button.form-input-button--button-link .form-input-button__button {
  flex-shrink: 0;
  margin: 0 0 4px;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #000000;
  transition: all 300ms ease-in-out;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}
.form-input-button.form-input-button--button-link .form-input-button__button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #000000;
  transition: all 200ms ease-in-out;
}
.form-input-button.form-input-button--button-link .form-input-button__button:hover, .form-input-button.form-input-button--button-link .form-input-button__button:focus {
  color: #1670E4;
}
.form-input-button.form-input-button--button-link .form-input-button__button:hover::after, .form-input-button.form-input-button--button-link .form-input-button__button:focus::after {
  background: #1670E4;
}

.form-input-button.form-control-invalid {
  border-color: #DE1947 !important;
}
.form-input-button.form-control-invalid .form-input-button__label {
  color: #DE1947;
}

.form-input-button.form-input-button--filled,
.form-input-button.form-control-filled {
  background: #FFFFFF;
  border-color: #E5E5E5;
}
.form-input-button.form-input-button--filled:focus-within,
.form-input-button.form-control-filled:focus-within {
  background: #FFFFFF;
  border-color: rgba(22, 112, 228, 0.8);
}
.form-input-button.form-input-button--filled .form-input-button__label,
.form-input-button.form-control-filled .form-input-button__label {
  top: -8px;
  left: 12px;
  background: #FFFFFF;
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.form-input-button.form-input-button--filled .form-input-button__input,
.form-input-button.form-control-filled .form-input-button__input {
  background: #FFFFFF;
  border-color: #E5E5E5;
  border-radius: 16px;
}

/*
* z-index de la aplicación
*/
.header-modal-container {
  position: relative;
  background: #FFFFFF;
}
.header-modal-container__hr {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  margin: 0;
  border: none;
  border-radius: 5px;
  background: rgba(229, 229, 229, 0.5);
}

.header-modal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 19px 0 20px;
}
.header-modal__logo {
  width: 152px;
  height: 35px;
  margin: 0 auto -2px -4px;
}
.header-modal__actions {
  margin-left: auto;
}

.btn-close {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 0 4px 0 9px;
  margin: 0 -4px 0 auto;
  background: none;
  border: none;
  border-radius: 50px;
  line-height: 16px;
  transition: all 300ms ease-in-out;
  outline: none !important;
}
.btn-close__icon {
  margin-left: 8px;
}
.btn-close__icon rect {
  transition: all 300ms ease-in-out;
}
.btn-close:hover, .btn-close:focus {
  background: #E5E5E5;
}
.btn-close:hover svg rect, .btn-close:focus svg rect {
  fill: #E5E5E5;
}

@media (min-width: 992px) {
  .header-modal {
    display: flex;
    align-items: center;
    padding: 19px 0;
  }
  .header-modal__logo {
    width: auto;
    height: auto;
    margin: 4px auto -4px -7px;
  }
  .header-modal__actions {
    margin-right: 15px;
  }
}
/*
* z-index de la aplicación
*/
.login-section-container {
  background-image: url(/static/website/images/pages/login/background.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center top;
}

.login-section {
  min-height: calc(100vh - 74px);
  text-align: center;
  padding: 36px 0 30px;
  max-width: 790px;
}
.login-section__title {
  max-width: 250px;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  margin: 0 auto 24px;
}
.login-section__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  margin-bottom: 36px;
}

.login-form .form-input {
  margin-bottom: 24px;
}
.login-form .form-input-button {
  padding-right: 10px;
}
.login-form .btn.btn--lg {
  padding: 20px 45px;
}
.login-form__forgot-password {
  text-align: left;
  margin-top: 14px;
}
.login-form__forgot-password .btn-link {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 18px;
}
.login-form__footer {
  margin-top: 108px;
}
.login-form__footer .form-doble-button {
  margin-bottom: 24px;
}
.login-form__singup {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: center;
}
.login-form__singup .btn-link {
  font-size: 14px;
  line-height: 18px;
}

.form-invalid-message {
  display: none;
  align-items: center;
  position: relative;
  margin-top: 29px;
  padding: 15px 23px;
  border: 2px solid #DE1947;
  border-radius: 16px;
}
.form-invalid-message::after {
  content: url(/static/website/images/pages/login/form-invalid-message-peak.svg);
  position: absolute;
  top: -16px;
  left: 23px;
}
.form-invalid-message__icon {
  flex-shrink: 0;
  margin-right: 16px;
}
.form-invalid-message__content {
  text-align: left;
}
.form-invalid-message__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #DE1947;
}
.form-invalid-message__body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}

.form-invalid-message.form-invalid-message--show {
  display: flex;
}

.form-doble-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-doble-button > * {
  width: 100%;
  max-width: 166px;
}
.form-doble-button > .btn.btn--lg {
  padding-left: 0;
  padding-right: 0;
}

.btn-login-google {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 22px;
  color: #000000;
  background: #FFFFFF;
  border: 2px solid #E5E5E5;
  box-shadow: 0 4px 0 #E5E5E5;
  border-radius: 46px;
  transition: all 200ms ease-in-out;
  outline: none;
}
.btn-login-google .icon {
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-left: 7px;
}
.btn-login-google:hover, .btn-login-google:focus {
  box-shadow: none;
}

@media (min-width: 768px) {
  .login-section-container {
    background-image: url(/static/website/images/pages/login/background-desktop.svg);
  }

  .login-section {
    max-width: 572px;
    min-height: calc(100vh - 80px);
    padding: 120px 0 60px;
  }
  .login-section__title {
    max-width: none;
    font-size: 56px;
    line-height: 60px;
    letter-spacing: -1px;
  }
  .login-section__subtitle {
    margin-bottom: 48px;
  }

  .login-form {
    margin: 0 auto;
    max-width: 378px;
  }
  .login-form__footer {
    margin-top: 47px;
  }

  .form-doble-button .btn.btn--lg {
    padding: 20px 50px;
  }
  .form-doble-button > * {
    width: auto;
    max-width: none;
  }

  .btn-login-google {
    padding: 8px 18px 8px 22px;
  }
  .btn-login-google .icon {
    vertical-align: middle;
    width: 36px;
    height: 36px;
    margin-left: 7px;
  }
}
@media (max-width: 320px) {
  .btn-login-google {
    padding: 8px 4px 8px 6px;
  }
}