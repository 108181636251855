@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

$header-padding-x: 15px;
$header-hr-height: 2px;

.header-modal-container {
    position: relative;
    background: var.$white;

    &__hr {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;

        height: $header-hr-height;
        margin: 0;
        border: none;
        border-radius: 5px;
        background: transparentize(var.$grey-3, .5);
    }
}

.header-modal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    padding: 19px 0 20px;

    &__logo {
        width: 152px;
        height: 35px;
        margin: 0 auto -2px -4px;
    }

    &__actions {
        margin-left: auto;
    }
}

.btn-close {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: var.$black;

    display: flex;
    align-items: center;

    padding: 0 4px 0 9px;
    margin: 0 -4px 0 auto;
    background: none;
    border: none;
    border-radius: 50px;

    line-height: 16px;

    transition: all 300ms ease-in-out;
    outline: none !important;

    &__icon {
        margin-left: 8px;
        rect {
            transition: all 300ms ease-in-out;
        }
    }

    &:hover,
    &:focus {
        background: var.$grey-3;
        svg {
            rect {
                fill: var.$grey-3;
            }
        }
    }
}

@media (min-width: var.$breakpoint-rule-desktop) {

    .header-modal {
        display: flex;
        align-items: center;
        padding: 19px 0;

        &__logo {
            width: auto;
            height: auto;
            margin: 4px auto -4px -7px;
        }

        &__actions {
            margin-right: 15px;
        }
    }
}
