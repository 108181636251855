@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.login-section-container {
    background-image: url(/static/website/images/pages/login/background.svg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center top;
}

.login-section {
    min-height: calc(100vh - 74px);
    text-align: center;
    padding: 36px 0 30px;
    max-width: 790px;

    &__title {
        max-width: 250px;
        @include mix.font-headlines-h4-28();
        margin: 0 auto 24px;
    }

    &__subtitle {
        @include mix.font-desktop-body-m();
        margin-bottom: 36px;
    }
}

.login-form {

    .form-input {
        margin-bottom: 24px;
    }

    .form-input-button {
        padding-right: 10px;
    }

    .btn.btn--lg {
        padding: 20px 45px;
    }

    &__forgot-password {
        text-align: left;
        margin-top: 14px;

        .btn-link {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__footer {
        margin-top: 108px;

        .form-doble-button {
            margin-bottom: 24px;
        }
    }

    &__singup {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: center;

        .btn-link {
            font-size: 14px;
            line-height: 18px;
        }
    }
}

.form-invalid-message {
    display: none;
    align-items: center;
    position: relative;

    margin-top: 29px;
    padding: 15px 23px;
    border: 2px solid #DE1947;
    border-radius: 16px;

    &::after {
        content: url(/static/website/images/pages/login/form-invalid-message-peak.svg);
        position: absolute;
        top: -16px;
        left: 23px;
    }

    &__icon {
        flex-shrink: 0;
        margin-right: 16px;
    }

    &__content {
        text-align: left;
    }

    &__title {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;

        color: #DE1947;
    }

    &__body {
        @include mix.font-body-xs();
    }
}

.form-invalid-message.form-invalid-message--show {
    display: flex;
}

.form-doble-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
        width: 100%;
        max-width: 166px;
    }

    & > .btn.btn--lg {
        padding-left: 0;
        padding-right: 0;
    }
}

.btn-login-google {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    padding: 8px 4px 8px 22px;

    color: var.$black;
    background: var.$white;
    border: 2px solid var.$grey-3;
    box-shadow: 0 4px 0 var.$grey-3;
    border-radius: 46px;

    transition: all 200ms ease-in-out;

    .icon {
        vertical-align: middle;
        width: 36px;
        height: 36px;
        margin-left: 7px;
    }

    outline: none;

    &:hover,
    &:focus {
        box-shadow: none;
    }
}

@media (min-width: var.$breakpoint-rule-tablet) {
    .login-section-container {
        background-image: url(/static/website/images/pages/login/background-desktop.svg);
    }

    .login-section {
        max-width: 572px;
        min-height: calc(100vh - 80px);
        padding: 120px 0 60px;

        &__title {
            max-width: none;
            @include mix.font-desktop-headlines-h2();
            letter-spacing: -1px;
        }

        &__subtitle {
            margin-bottom: 48px;
        }
    }

    .login-form {
        margin: 0 auto;
        max-width: 378px;

        &__footer {
            margin-top: 47px;
        }
    }

    .form-doble-button {
        .btn.btn--lg {
            padding: 20px 50px;
        }

        & > * {
            width: auto;
            max-width: none;
        }
    }


    .btn-login-google {
        padding: 8px 18px 8px 22px;

        .icon {
            vertical-align: middle;
            width: 36px;
            height: 36px;
            margin-left: 7px;
        }
    }
}

@media (max-width: var.$breakpoint-rule-gulliver) {
    .btn-login-google {
        padding: 8px 4px 8px 6px;
    }
}
