@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

.form-input-button {
    position: relative;
    display: flex;
    align-items: center;
    background: var.$light-grey;
    border: 2px solid var.$light-grey;
    border-radius: 16px;

    padding-right: 18px;

    transition: all 200ms ease-in-out;

    &:focus-within {
        background: var.$white;
        border-color: rgba(22, 112, 228, 0.8);
    }

    &__button {

    }

    &__label {
        position: absolute;
        pointer-events: none;
        top: 18px;
        left: 15px;

        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;

        padding: 0 6px;
        color: var.$grey-1;
        background: rgba(255, 255, 255, 0);

        transition: all 200ms ease-in-out;
    }

    &__input {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;

        padding: 16px 0 16px 22px;

        width: 100%;

        color: var.$black;
        background: var.$light-grey;
        border: none;
        border-radius: 16px;
        outline: none;

        transition: all 200ms ease-in-out;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &:focus,
        &:valid {
            background: var.$white;
            border-color: var.$grey-3;
            border-radius: 16px;
        }

        &:focus {
            border-color: rgba(22, 112, 228, 0.8);
        }
    }

    &__input:focus + &__label,
    &__input:valid + &__label {
        top: -8px;
        left: 12px;
        background: var.$white;

        font-family: 'Space Grotesk', sans-serif;
        @include mix.font-subtitle-m();
        text-transform: uppercase;
    }
}

.form-input-button.form-input-button--button-link {
    .form-input-button__button {
        flex-shrink: 0;
        margin: 0 0 4px;
        position: relative;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.01em;

        color: var.$black;

        transition: all 300ms ease-in-out;

        //RESET
        padding: 0;
        background: none;
        border: none;
        outline: none;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            height: 1px;
            background: var.$black;

            transition: all 200ms ease-in-out;
        }

        &:hover,
        &:focus {
            color: var.$electric-blue;

            &::after {

                background: var.$electric-blue;
            }
        }
    }
}

.form-input-button.form-control-invalid {
    border-color: var.$red-error !important;

    .form-input-button__label {
        color: var.$red-error;
    }
}

.form-input-button.form-input-button--filled,
.form-input-button.form-control-filled {
    background: var.$white;
    border-color: var.$grey-3;

    &:focus-within {
        background: var.$white;
        border-color: rgba(22, 112, 228, 0.8);
    }

    .form-input-button__label {
        top: -8px;
        left: 12px;
        background: var.$white;

        font-family: 'Space Grotesk', sans-serif;
        @include mix.font-subtitle-m();
        text-transform: uppercase;
    }

    .form-input-button__input {
        background: var.$white;
        border-color: var.$grey-3;
        border-radius: 16px;
    }
}
