@use "/website/assets/scss/utilities/variables" as var;
@use "/website/assets/scss/utilities/mixins" as mix;

@mixin label-raised() {
    top: -6px;
    left: 14px;
    background: var.$white;


    font-family: 'Space Grotesk', sans-serif;
    @include mix.font-subtitle-m();
    text-transform: uppercase;
}

.form-input {
    position: relative;

    &__label {
        position: absolute;
        pointer-events: none;
        top: 20px;
        left: 18px;

        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;

        padding: 0 6px;
        color: var.$grey-1;
        background: rgba(255, 255, 255, 0);

        transition: all 200ms ease-in-out;
    }

    &__input {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;

        padding: 16px 22px;
        width: 100%;

        color: var.$black;
        background: var.$light-grey;
        border: 2px solid var.$light-grey;
        border-radius: 16px;
        outline: none;

        transition: all 200ms ease-in-out;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active
        {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &:focus,
        &:valid {
            background: var.$white;
            border-color: var.$grey-3;
            border-radius: 16px;
        }

        &:focus {
            border-color: rgba(22, 112, 228, 0.8);
        }
    }

    &__input:focus + &__label {
        @include label-raised();
    }
}

.form-input:not(.form-control-forced-focus) .form-input__input:valid + .form-input__label {
    @include label-raised();
}

.form-input.form-control-invalid {
    .form-input__label {
        color: var.$red-error;
    }

    .form-input__input {
        border-color: var.$red-error !important;
    }
}

.form-input.form-control-filled {
    .form-input__input {
        background: var.$white;
        border-color: var.$grey-3;
        border-radius: 16px;

        &:focus {
            border-color: rgba(22, 112, 228, 0.8);
        }
    }

    .form-input__label {
        top: -6px;
        left: 14px;
        background: var.$white;


        font-family: 'Space Grotesk', sans-serif;
        @include mix.font-subtitle-m();
        text-transform: uppercase;
    }
}

.form-input.form-input--optional {
    .form-input__optional {
        pointer-events: none;
        position: absolute;
        top: 17px;
        right: 24px;
        color: var.$grey-1;
        @include mix.font-body-xs();
    }

    .form-input__input {
        padding: 16px #{22 + 56 + 10}px 16px 22px;
    }
}
