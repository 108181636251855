.icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
}

.icon.icon-email {
    background-image: url(/static/website/images/icons/icon-email.svg);
}

.icon.icon-whatsapp {
    background-image: url(/static/website/images/icons/icon-whatsapp.svg);
}

.icon.icon-twitter {
    background-image: url(/static/website/images/icons/icon-twitter.svg);
}

.icon.icon-logo-google {
    background-image: url(/static/website/images/icons/icon-logo-google.svg);
}

.icon.icon-close-button {
    background-image: url(/static/website/images/icons/icon-close-button.svg);
}
