@use "/website/assets/scss/utilities/variables" as var;

$wrapper-padding-x: 15px;

.section-container {
    padding: 0 $wrapper-padding-x;

    &__wrapper {
        margin: 0 auto;
    }
}



@media (min-width: map-get(var.$grid-breakpoints, 'md')) {
    $wrapper-padding-x: 48px;

    .section-container {
        padding: 0 $wrapper-padding-x;
    }
}

@media (min-width: map-get(var.$grid-breakpoints, 'lg')) {
    $wrapper-padding-x: 15px;

    .section-container {
        padding: 0 $wrapper-padding-x;

        &__wrapper {
            max-width: var.$max-content-width;
        }
    }
}
